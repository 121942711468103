import { backgroundColorsChart, backgroundColorsTransparent } from './chartjs-config';
import moment from 'moment/moment';

export function getRandomColorSolid(index: number) {
  return backgroundColorsChart[index % backgroundColorsChart.length];
}

export function getRandomColorTransparent(index: number) {
  return backgroundColorsTransparent[index % backgroundColorsTransparent.length];
}

export const calculateAge = (birthday: string | Date): number => {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const hasBirthdayPassedThisYear =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());
  if (!hasBirthdayPassedThisYear) {
    age -= 1;
  }
  return age;
}

export const calculateTimeFrom = (startDate: string | Date, referenceDate: string | Date): string => {
  const start = new Date(startDate);
  const reference = new Date(referenceDate);

  if (start > reference) {
    throw new Error('La fecha de inicio no puede ser posterior a la fecha de referencia.');
  }

  const years = reference.getFullYear() - start.getFullYear();
  let months = reference.getMonth() - start.getMonth();
  let days = reference.getDate() - start.getDate();

  if (days < 0) {
    const lastDayOfPreviousMonth = new Date(reference.getFullYear(), reference.getMonth(), 0).getDate();
    days += lastDayOfPreviousMonth;
    months -= 1;
  }
  if (years > 0) {
    const adjustedYears = months < 0 ? years - 1 : years;
    return `${adjustedYears} año${adjustedYears === 1 ? '' : 's'}`;
  }
  if (months > 0) {
    return `${months} mes${months === 1 ? '' : 'es'}`;
  }
  return `${days} día${days === 1 ? '' : 's'}`;
};

export const formatDate = (date: string) => {
  return moment.utc(date)
    .local()
    .format('DD/MM/YYYY');
};