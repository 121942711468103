/* eslint-disable react/prop-types */
import { Add, AdminPanelSettings, BorderColor, CloudDownload, CloudUpload, PersonOff } from '@mui/icons-material';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { Box, Button, ListItemIcon, MenuItem, TextField, ThemeProvider, Typography } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import {
  listCompanies,
  listCompanyEmployees,
  listSearchTextEmployees,
  listUserEmployees,
  updateUserPassword,
} from 'api/user/user.api';
import { selectUser } from 'global-state/selectors';
import { validateEmail, validateRequired } from 'helpers/validation.helpers';
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Edit } from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserRoleEnum } from 'types/user-role.enum';
import { UserStatusEnum } from 'types/user-status.enum';
import { EmployeeDeleteDTO, User } from 'types/user.dto';
import { logger } from 'utilities/logger/Logger';
import { read, utils, writeFile } from 'xlsx';
import {
  activateEmployee,
  createEmployee,
  createEmployeeAdmin,
  createEmployeesFromXML,
  createEmployeesFromXMLAdmin,
  deactivateEmployee, deleteEmployeesFromXML,
  editEmployee,
  editEmployeesFromXML,
} from '../../../api/employee/employee.api';
import { violetTheme } from '../../../design/theme/palette';
import { EmployeeCreateDTO } from '../../../types/employee-create.dto';
import CompaniesDropDown from './CompanyDropdown';
import { CreateEmployeeModal } from './CreateEmployeeModal';
import { EditEmployeeModal } from './EditEmployeeModal';
import moment from 'moment';
import { getErrorCodeFromErrorMessage } from '../../../helpers/error.helpers';
import appLayoutMap from '../../../utilities/appLayoutMap';
import { StyledP, StyledTitleContainer } from '../../../design/theme/shared-style';
import { DeactivateEmployeeModal } from './DeactivateEmployeemodal';
import { ImportUserResultsModal } from './ImportUserResults';
import PasswordIcon from '@mui/icons-material/Password';
import { EditEmployeePasswordModal } from './EditEmployeePassword';
import { StorageItem } from '../../../enums/storage-item.enum';
import { storage } from '../../../helpers/storage.helpers';
import { getListProfiles } from '../../../api/company/company.api';
import Skeleton from 'react-loading-skeleton';
import { ModalToken } from '../../Shared/ModalToken/ModalToken';
import {
  calculateObjectSizeInBytes,
  generateExcelTemplate,
  HandleImportOptions,
  mapXmlPropertiesToDTO,
} from './Utils/employee.utils';
import { exportEmployeeData } from './Utils/xlsx.utils';
import DeleteIcon from '@mui/icons-material/Delete';

const ListCompanyTable: FC<{
  usersPreloaded?: boolean,
  usersToLoad?: User[] | null
}> = ({ usersPreloaded, usersToLoad }) => {
  const [tableData, setTableData] = useState<User[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [CreateCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
  const [EditCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState<boolean>(false);
  const [updatePassUserModalOpen, setUpdatePassUserModalOpen] = useState<boolean>(false);
  const [tokenModalOpen, setTokenModalOpen] = useState<boolean>(false);
  const [importUserResultsModalOpen, setImportUserResultsModalOpen] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [employees, setEmployees] = useState<User[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const loggedUser = useSelector(selectUser);
  const [searchText, setSearchText] = useState<string>('');
  const [userToDeactivate, setUserToDeactivate] = useState<User>();
  const [userToUpdatePassword, setUserToUpdatePassword] = useState<User>();
  const [resultsInfo, setResultsInfo] = useState<any>();
  const [profileList, setProfileList] = useState<any[] | null>(null);
  const companySelected: any = storage.session.get(StorageItem.COMPANY_SELECTED);
  const [successTokenFunction, setSuccessTokenFunction] = useState<(() => void) | null>(null);
  const tableInstanceRef = useRef<any>(null);

  const getUsers = async () => {
    setLoading(true);
    if (loggedUser?.role === UserRoleEnum.COMPANY) {
      const users = await listUserEmployees();
      if (users && users.length > 0) {
        setTableData(users);
        setEmployees(users);
      }
    } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
      const companiesList = await listCompanies();

      if (companiesList && companiesList.length > 0) {
        setCompanies(companiesList);
      }
    }
    setLoading(false);
  };

  const loadUsers = async (users: User[]) => {
    if (users && users.length > 0) {
      setTableData(users);
      setEmployees(users);
    } else if (users && users.length === 0) {
      setTableData([]);
      setEmployees([]);
    }
  };

  const toggleUserModal = () => {
    setCreateCompanyModalOpen(!CreateCompanyModalOpen);
    setSuccessTokenFunction(null);
  };

  useEffect(() => {
    if (!usersToLoad) {
      const delaySearch = setTimeout(() => {
        const fetchData = async () => {
          try {
            if (searchText !== null) {
              setLoading(true);
              const users = await listSearchTextEmployees(searchText, selectedCompanyId);
              await loadUsers(users);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };

        if (searchText !== null) {
          fetchData();
        }
      }, 500);

      return () => clearTimeout(delaySearch);
    }
  }, [searchText]);

  const setCompanyFromDropDown = async (companyId: string) => {
    setLoading(true);
    setSelectedCompanyId(companyId);
    const users = await listSearchTextEmployees(searchText, companyId);
    await loadUsers(users);
    setLoading(false);
  };

  const getUsersForAdmin = async () => {
    setLoading(true);
    const users = await listCompanyEmployees(selectedCompanyId, searchText);
    await loadUsers(users);
    setLoading(false);
  };

  const handleInputChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const loadProfilesFromCompany = async (companyId?: string) => {
    if (selectedCompanyId || companyId) {
      setLoading(true);
      const profileList = await getListProfiles(companyId || selectedCompanyId);
      if (profileList && profileList.length > 0) {
        setProfileList(profileList);
      } else {
        setProfileList(null);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!usersPreloaded && !usersToLoad) {
      getUsers();
    } else if (usersToLoad) {
      loadUsers(usersToLoad);
    }
  }, []);

  useEffect(() => {
    if (loggedUser?.role === UserRoleEnum.ADMIN) {
      loadProfilesFromCompany();
    }
  }, [selectedCompanyId]);

  const handleInviteUser = async (userTableData: any) => {
    const newEmployee: EmployeeCreateDTO = {
      name: userTableData.name,
      firstLastname: userTableData.firstLastname,
      secondLastname: userTableData.secondLastname,
      sex: userTableData.sex,
      rfc: userTableData.rfc,
      curp: userTableData.curp,
      email: userTableData.email,
      mobile: userTableData.mobile,
      birthday: new Date(userTableData.birthday),
      companyEmployeeNumber: userTableData.companyEmployeeNumber,
      position: userTableData.position,
      area: userTableData.area,
      location: userTableData.location,
      startDate: userTableData.startDate
        ? new Date(userTableData.startDate)
        : null,
      companyEmail: userTableData.companyEmail,
      netSalary: userTableData.netSalary,
      paymentFrequency: userTableData.paymentFrequency,
      clabe: userTableData.clabe,
      bank: userTableData.bank,
      appLayout: userTableData.appLayout || '0',
      profile: userTableData.profile,
      salaryServiceEnable: userTableData.salaryServiceEnable || false
    };
    try {
      toast.info('Cargando información.');
      if (loggedUser?.role === UserRoleEnum.COMPANY) {
        await createEmployee(newEmployee);
        setCreateCompanyModalOpen(false);
        await getUsers();
        toast.success('Se ha creado la solicitud de alta correctamente.');
      } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
        newEmployee.selectedCompanyId = selectedCompanyId;
        await createEmployeeAdmin(newEmployee);
        setCreateCompanyModalOpen(false);
        await getUsersForAdmin();
        toast.success('El colaborador ha sido creado satisfactoriamente.');
      }
    } catch (error: any) {
      console.log('error', error.message);
      toast.dismiss();
      if (error.message === 'CLABE already in use.') {
        toast.error('La CLABE ya está siendo utilizada por otro usuario.');
      } else if (error.message.includes('status code')) {
        const statusCode = getErrorCodeFromErrorMessage(error.message);
        if (statusCode === 409) {
          toast.error('Dirección de correo electrónico ya existente en sistema Twispi');
        } else {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        }
      } else {
        toast.error('Ha ocurrido un error, intenta nuevamente');
      }
    }
  };

  const handleEditButton = async (userSelectedRow: User) => {
    setUserToEdit(userSelectedRow);
    if (loggedUser?.role === UserRoleEnum.ADMIN && !selectedCompanyId) {
      await loadProfilesFromCompany(userSelectedRow.company.id);
    }
    setEditCompanyModalOpen(true);
  };

  const handleDeactivateButton = (userSelectedRow: User) => {
    setUserToDeactivate(userSelectedRow);
    setDeactivateUserModalOpen(true);
  };

  const handleUpdatePasswordButton = (userSelectedRow: User) => {
    setUserToUpdatePassword(userSelectedRow);
    setUpdatePassUserModalOpen(true);
  };

  const updateUser = async (userData: EmployeeCreateDTO) => {
    try {
      if (userToEdit) {
        const response = await editEmployee(userToEdit.id, userData);
        if (loggedUser?.role === UserRoleEnum.COMPANY) {
          if(response.authorization) {
            toast.success('Se ha creado la solicitud de edición correctamente.');
          } else {
            toast.success('El registro ha sido actualizado');
          }
          const paginationPreviousUpdate = pagination;
          await getUsers();
          setTimeout(() => {
            setPagination(paginationPreviousUpdate);
          }, 700);
          setSuccessTokenFunction(null);
        } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
          toast.success('El registro ha sido actualizado');
          await getUsersForAdmin();
        }
      }
    } catch (error: any) {
      toast.dismiss();
      if (error.message === 'CLABE already in use.') {
        toast.error('La CLABE ya está siendo utilizada por otro usuario.');
      }
    }
  };

  const handleEditUser = async (userData: EmployeeCreateDTO) => {
    try {
      const indexOfClabe = (userToEdit?.clabes as { active: boolean }[])?.findIndex(clabe => clabe.active);
      if (loggedUser?.role === UserRoleEnum.COMPANY && (userData.netSalary !== userToEdit?.netSalary || userData.clabe !== userToEdit?.clabes?.[indexOfClabe]?.code)) {
        setSuccessTokenFunction(() => () => updateUser(userData));
        setTokenModalOpen(true);
      } else {
        await updateUser(userData);
      }
    } catch (error: any) {
      if (error.message.includes('status code')) {
        const statusCode = getErrorCodeFromErrorMessage(error.message);
        if (statusCode === 409) {
          toast.error('Dirección de correo electrónico ya existente en sistema Twispi');
        } else {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        }
      } else {
        toast.error('Ha ocurrido un error, intenta nuevamente');
      }
    }
  };

  const handleDeactivate = async (userSelectedRow: User, endData: {
    endDate: Date,
    endReason: string
  }) => {
    try {
      const result = confirm(
        `Quieres desactivar al colaborador ${userSelectedRow.email}?`,
      );

      if (result) {
        await deactivateEmployee(userSelectedRow.id, endData);
        toast.info('El colaborador ha sido desactivado', {
          position: 'top-center',
        });

        if (loggedUser?.role === UserRoleEnum.COMPANY) {
          const paginationPreviousUpdate = pagination;
          await getUsers();
          setTimeout(() => {
            setPagination(paginationPreviousUpdate);
          }, 700);
        } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
          await getUsersForAdmin();
        }
      }
    } catch (error) {
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: 'top-center',
      });
    }
  };

  const handleActivate = async (userSelectedRow: User) => {
    try {
      const result = confirm(`Quieres activar ${userSelectedRow.email}?`);

      if (result) {
        await activateEmployee(userSelectedRow.id);
        toast.info('El colaborador ha sido activado', {
          position: 'top-center',
        });
        if (loggedUser?.role === UserRoleEnum.COMPANY) {
          const paginationPreviousUpdate = pagination;
          await getUsers();
          setTimeout(() => {
            setPagination(paginationPreviousUpdate);
          }, 700);
        } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
          await getUsersForAdmin();
        }
      }
    } catch (error) {
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: 'top-center',
      });
    }
  };

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<User>,
    ): MRT_ColumnDef<User>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const handleDeactivateUser = (endData: {
    endDate: Date,
    endReason: string
  }) => {
    if (userToDeactivate) {
      handleDeactivate(userToDeactivate, endData);
    }
  };

  const updatePassword = async (newPassword: string) => {
    try {
      if (userToUpdatePassword) {
        await updateUserPassword(userToUpdatePassword.id, newPassword);
        toast.info('Contraseña actualizada correctamente', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: 'top-center',
      });
    }
  };

  const handleUpdatePassword = (newPassword: string) => {
    if (newPassword) {
      updatePassword(newPassword);
    }
  };

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'status',
        header: 'Habilitado',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>() === UserStatusEnum.Active) {
            return <span>✅ Activo</span>;
          } else if (props.cell.getValue<string>() === UserStatusEnum.Pending) {
            return <span>🕐 Pendiente App</span>;
            // eslint-disable-next-line no-dupe-else-if
          } else if (props.cell.getValue<string>() === UserStatusEnum.Deactivated) {
            return <span>❌ Deshabilitado</span>;
          } else {
            return <span>⏳ En revisión</span>;
          }
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'poliza',
        header: 'Poliza',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'name',
        header: 'Nombre',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'firstLastname',
        header: 'Apellido Paterno',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'secondLastname',
        header: 'Apellido Materno',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'birthday',
        header: 'Fecha de nacimiento',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (props.cell.getValue<string>() && props.cell.getValue<string>() !== 'null') {
            const formattedDate = moment.utc(props.cell.getValue<string>()).format('DD/MM/YYYY');
            return <span>{formattedDate}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'startDate',
        header: 'Fecha de ingreso',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          const rawStartDate = props.cell.getValue<string>();
          if (rawStartDate && rawStartDate !== 'null') {
            const formattedStartDate = moment.utc(rawStartDate).format('DD/MM/YYYY');
            return <span>{formattedStartDate}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'endDate',
        header: 'Fecha de egreso',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return (
              <span>
                {new Date(props.cell.getValue<string>()).toLocaleDateString(
                  'es-419',
                )}
              </span>
            );
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'twispiEndDate',
        header: 'Fecha de baja Twispi',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            const fecha = new Date(props.cell.getValue<string>());
            const opcionesFormato: Intl.DateTimeFormatOptions = {
              hour: '2-digit',
              minute: '2-digit',
            };
            const horaYMinutos = fecha.toLocaleTimeString('es-419', opcionesFormato);
            const fechaFormateada = `${fecha.toLocaleDateString('es-419')} ${horaYMinutos}`;
            return (
              <span>
                {fechaFormateada}
              </span>
            );
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'paymentFrequency',
        header: 'Frecuencia de pago',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'mobile',
        header: 'Teléfono',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'curp',
        header: 'CURP',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (props.cell.getValue<string>()) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'position',
        header: 'Puesto',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'companyEmail',
        header: 'Email empresarial',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'area',
        header: 'Área',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'rfc',
        header: 'RFC',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'netSalary',
        header: 'Sueldo neto',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (props.cell.getValue<any>()) {
            return <span>$ {typeof props.cell.getValue<any>() !== 'number' ? props.cell.getValue<any>().salaryAmount : props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'sex',
        header: 'Sexo',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (props.cell.getValue<string>()) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'clabes',
        header: 'CLABE',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ cell }) => {
          try {
            const indexActive = cell.getValue<any>()?.findIndex((clabe: any) => clabe.active === true);
            return <span>{cell.getValue<any>()[indexActive]?.code || '--'}</span>;
          } catch (e: unknown) {
            if (e instanceof Error) {
              logger.error(e);
            }
            return null;
          }
        },
      },
      {
        accessorKey: 'clabes',
        header: 'Banco',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ cell }) => {
          try {
            const indexOfClabe = cell.getValue<any>()?.findIndex((clabe: any) => clabe.active === true);
            return <span>{(cell.getValue<any>()[indexOfClabe]?.active && cell.getValue<any>()[indexOfClabe]?.bank?.code) || '--'}</span>;
          } catch (e: unknown) {
            if (e instanceof Error) {
              logger.error(e);
            }
            return null;
          }
        },
      },
      {
        accessorKey: 'location',
        header: 'Ubicación',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ cell }) => {
          try {
            return <span>{cell.getValue<any>() || '--'}</span>;
          } catch (e: unknown) {
            if (e instanceof Error) {
              logger.error(e);
            }
            return null;
          }
        },
      },
      {
        accessorKey: 'appLayout',
        header: 'Servicios disponibles',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ cell }) => {
          try {
            return <span>{appLayoutMap[cell.getValue<any>()] || '--'}</span>;
          } catch (e: unknown) {
            if (e instanceof Error) {
              logger.error(e);
            }
            return null;
          }
        },
      },
      {
        accessorKey: 'profiles',
        header: 'Perfil de servicio',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ cell }) => {
          try {
            const profiles = cell.getValue<any>();
            let latestActiveProfile: any;
            if (profiles && profiles.length > 0) {
              latestActiveProfile = profiles?.reduce((prev: any, current: any) => (prev.updatedAt > current.updatedAt) ? prev : current);
            }
            return <span>{latestActiveProfile?.profile.name || '--'}</span>;
          } catch (e: unknown) {
            if (e instanceof Error) {
              logger.error(e);
            }
            return null;
          }
        },
      },
      {
        accessorKey: 'companyEmployeeNumber',
        header: 'Número de empleado',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
    ],
    [getCommonEditTextFieldProps],
  );

  const saveNewEmployees = async (mappedItems: any[]) => {
    try {
      let responseUserXLS;
      const employeesData = {
        selectedCompanyId,
        employees: mappedItems,
      };
      if(mappedItems && mappedItems.length > 120) {
        toast.dismiss();
        if (loggedUser?.role === UserRoleEnum.COMPANY) {
          toast.info('La carga continuará en segundo plano y podrá autorizarla al finalizar.');
          createEmployeesFromXML(mappedItems);
          getUsers();
        } else {
          toast.info('La carga continuará en segundo plano y podrá ir visualizando los colaboradores nuevos.');
          createEmployeesFromXMLAdmin(employeesData);
          getUsersForAdmin();
        }
        return;
      }
      if (loggedUser?.role === UserRoleEnum.COMPANY) {
        responseUserXLS = await createEmployeesFromXML(mappedItems);
        const message = `Usuarios creados: ${responseUserXLS.counterUsersCreated} / Usuarios no creados: ${responseUserXLS.counterUserExists}`;
        const messageParts = message.split(' / ');
        toast(
          <div>
            <p>✅ {messageParts[0]}</p>
            <p>❌ {messageParts[1]}</p>
          </div>,
          {
            autoClose: 10000,
            position: toast.POSITION.TOP_CENTER,
          },
        );

        await getUsers();
      } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
        responseUserXLS = await createEmployeesFromXMLAdmin(employeesData);
        const message = `Usuarios creados: ${responseUserXLS.counterUsersCreated} / Usuarios no creados: ${responseUserXLS.counterUserExists}`;
        const messageParts = message.split(' / ');
        toast(
          <div>
            <p>✅ {messageParts[0]}</p>
            <p>❌ {messageParts[1]}</p>
          </div>,
          {
            autoClose: 10000,
            position: toast.POSITION.TOP_CENTER,
          },
        );

        await getUsersForAdmin();
      }
      setImportUserResultsModalOpen(true);
      setResultsInfo(responseUserXLS.detailInfo);
    } catch (error: any) {
      if(error.message === 'Network Error') {
        toast.info('La carga de archivos está tardando más de lo esperado. Continuará en segundo plano y podrá autorizarla al finalizar.');
        if (loggedUser?.role === UserRoleEnum.COMPANY) {
          await getUsers();
        } else {
          await getUsersForAdmin();
        }
        return;
      }
      if(error.message === 'Request failed with status code 404') {
        toast.error('No se ha encontrado un autorizador asignado. Por favor, contacta al equipo de soporte.');
        return;
      }
      toast.error('Ha ocurrido un error, intenta nuevamente');
    }
  };

  const editMasiveEmployees = async (mappedItems: any) => {
    try {
      const responseUserEdit = await editEmployeesFromXML(mappedItems);
      return responseUserEdit;
    } catch (error) {
      toast.error('Ha ocurrido un error, intenta nuevamente');
    }
  };

  const deleteMasiveEmployees = async (mappedItems: EmployeeDeleteDTO[]) => {
    try {
      const deletedCount = await deleteEmployeesFromXML(mappedItems);
      if (loggedUser?.role === UserRoleEnum.COMPANY) {
        await getUsers();
      } else if (loggedUser?.role === UserRoleEnum.ADMIN) {
        await getUsersForAdmin();
      }
      return deletedCount;
    } catch (error) {
      toast.error('Ha ocurrido un error, intenta nuevamente');
    }
  };

  const hasRecommendedHeader = (item: any) => {
    const firstObject = item[0];
    return Object.values(firstObject).some((value: any) => {
      if (typeof value !== 'string') {
        return false;
      }
      return value?.toUpperCase() === 'OBLIGATORIO' || value?.toUpperCase() === 'OPCIONAL' || value?.toUpperCase() === 'RECOMENDADO';
    });
  };

  const handleImport = async (
    $event: React.FocusEvent<HTMLInputElement, Element>,
    options: HandleImportOptions,
  ) => {
    const files = $event.target.files;
    if (!!files && files.length) {
      const file = files[0];

      if (!window.confirm(options.confirmMessage)) {
        if ($event.target) {
          $event.target.value = '';
        }
        return;
      }

      setLoading(true);
      toast.info(options.loadingMessage, {
        position: toast.POSITION.TOP_CENTER,
      });

      const reader = new FileReader();
      reader.onload = async (event) => {
        if (!!event && !!event.target) {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
          if (sheets.length) {
            const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            if (!!rows && rows.length) {
              if (hasRecommendedHeader(rows)) {
                rows.shift();
              }
              const mappedItems = mapXmlPropertiesToDTO(rows);
              const valuesEmpty = Object.values(mappedItems).every(value => value === null || value === undefined || value === 0 || value instanceof Date);
              if (valuesEmpty) {
                toast.dismiss();
                toast.error('No se detectaron campos válidos en el archivo.', {
                  position: toast.POSITION.TOP_CENTER,
                });
                setLoading(false);
                return;
              }
              const sizeInBytes = calculateObjectSizeInBytes(mappedItems);
              if (sizeInBytes > 90000) {
                toast.dismiss();
                toast.error('El archivo es demasiado grande. Por favor, elimina las filas innecesarias o divide el archivo para que cumpla con el tamaño permitido.', {
                  position: toast.POSITION.TOP_CENTER,
                });
                setLoading(false);
                return;
              }
              const result = await options.processItems(mappedItems);
              if (typeof result === 'number') {
                toast.dismiss();
                if (result === 0 && options.noChangesMessage) {
                  toast.info(options.noChangesMessage, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                } else if (result > 0 && options.successMessage) {
                  toast.success(options.successMessage(result), {
                    position: toast.POSITION.TOP_CENTER,
                  });
                } else if (options.errorMessage) {
                  toast.error(options.errorMessage, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
              }
              if (options.afterProcess) {
                await options.afterProcess();
              }
            }
          }
        }
        setLoading(false);
        if ($event.target) {
          $event.target.value = '';
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setLoading(false);
      if ($event.target) {
        $event.target.value = '';
      }
    }
  };

  const handleImportEmployee = (
    $event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    handleImport($event, {
      confirmMessage: `¿Desea importar los datos ${$event.target.files?.[0]?.name}?`,
      loadingMessage: 'Importando colaboradores...',
      processItems: async (items) => {
        await saveNewEmployees(items);
      },
    });
  };

  const handleImportMasiveUpdate = (
    $event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    handleImport($event, {
      confirmMessage: `¿Desea realizar una modificación en lote con los datos de "${$event.target.files?.[0]?.name}"?`,
      loadingMessage: 'Cargando información...',
      successMessage: (count) => `Se ha${count === 1 ? '' : 'n'} modificado${count === 1 ? '' : 's'} ${count} colaborador${count === 1 ? '' : 'es'} correctamente.`,
      noChangesMessage: 'No se han encontrado colaboradores con información válida para modificar.',
      errorMessage: 'Ocurrió un error analizando la modificación',
      processItems: async (items) => {
        return await editMasiveEmployees(items);
      },
      afterProcess: () => {
        setSearchText('');
      },
    });
  };

  const handleImportMasiveDelete = (
    $event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    handleImport($event, {
      confirmMessage: `¿Desea realizar una eliminación en lote con los datos de "${$event.target.files?.[0]?.name}"?`,
      loadingMessage: 'Leyendo información...',
      successMessage: (count) => `Se ha${count === 1 ? '' : 'n'} eliminado ${count} colaborador${count === 1 ? '' : 'es'} correctamente.`,
      noChangesMessage: 'No se han encontrado colaboradores con información válida para eliminar.',
      errorMessage: 'Ocurrió un error analizando los datos',
      processItems: async (items) => {
        const usersToDeactivate = items.map(({ id, email, reason }: {id: string, email: string, reason:string}) => ({ id, email, reason }));
        return await deleteMasiveEmployees(usersToDeactivate);
      },
      afterProcess: () => {
        setSearchText('');
      },
    });
  };

  const handleClickImport = () => {
    if (loading || (loggedUser?.role === UserRoleEnum.COMPANY && companySelected && companySelected.id === 0)) {
      return;
    }
    if (loggedUser?.role === UserRoleEnum.COMPANY) {
      setSuccessTokenFunction(() => openImportModal);
      setTokenModalOpen(true);
    } else {
      openImportModal();
    }
  };

  const handleClickImportMasiveUpdate = () => {
    document.getElementById('file-input-masive-update')?.click();
  };

  const handleClickImportMasiveDelete = () => {
    document.getElementById('file-input-masive-delete')?.click();
  };

  const openImportModal = () => {
    document.getElementById('file-input')?.click();
    setSuccessTokenFunction(null);
  };

  const formatEmployeesToExport = (employees: User[]) => {
    return employees.map((employee) => {
      return {
        name: employee.name,
        firstLastname: employee.firstLastname,
        secondLastname: employee.secondLastname,
        sex: employee.sex,
        birthday: employee.birthday,
        startDate: employee.startDate,
        paymentFrequency: employee.paymentFrequency,
        payDay: '',
        payDate1: '',
        payDate2: '',
        companyEmail: employee.companyEmail,
        mobile: employee.mobile,
        clabe: '',
        currency: '',
        percentageDiscount: '',
        employeeId: '',
        curp: employee.curp,
        position: employee.position,
        email: employee.email,
        area: employee.area,
        rfc: employee.rfc,
        netSalary: employee.netSalary,
        debitCard: '',
        bank: '',
        bankCode: '',
        maxTransactionsPerPeriod: '',
        photo: '',
      };
    });
  };

  const formatEmployeesToExportIKE = (employees: any[]) => {
    return employees.map((employee: any) => {
      const today = new Date();
      const year = today.getFullYear();
      const nextYear = year + 1;
      const month = ('0' + (today.getMonth() + 1)).slice(-2); // Months are 0 based in JS, so +1 and pad with 0 if needed
      const day = ('0' + today.getDate()).slice(-2);

      const startDateForm = year + '-' + month + '-' + day;
      const endDateForm = nextYear + '-' + month + '-' + day;

      return {
        clave: `${employee.poliza}`,
        firstLastname: employee.firstLastname,
        secondLastname: employee.secondLastname,
        name: employee.name,
        startDate: `${startDateForm}`,
        endDate: `${endDateForm}`,
        movimiento: 'A',
        programa: 'Asistencia Twispi',
      };
    });
  };

  const handleExport = () => {
    const headings = [
      [
        'Nombre(s)',
        'Apellido Paterno',
        'Apellido Materno',
        'Sexo',
        'Fecha de Nacimiento',
        'Fecha de Ingreso',
        'Periodicidad',
        'Email empresarial',
        'Telefono',
        'CLABE',
        'Moneda',
        'ID de Empleado',
        'CURP',
        'Puesto',
        'Correo electrónico',
        'Area',
        'RFC',
        'Sueldo neto',
        'Banco',
        'Codigo banco',
        'Transacciones máximas por período',
        'Fotografía',
      ],
    ];
    const formattedEmployees = formatEmployeesToExport(employees);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, formattedEmployees, {
      origin: 'A2',
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, 'Report');
    const currentDate = new Date().toLocaleDateString('es-419');
    writeFile(wb, `Colaboradores-${currentDate}.xlsx`);
  };

  const handleExportIKE = () => {
    const headings = [
      [
        'Clave',
        'Apellido paterno',
        'Apellido materno',
        'Nombre',
        'Fecha de inicio de vigencia',
        'Fecha de fin de vigencia',
        'Tipo Movimiento',
        'Programa',
      ],
    ];
    const formattedEmployees = formatEmployeesToExportIKE(employees);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, formattedEmployees, {
      origin: 'A2',
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, 'HOJA 1');
    const currentDate = new Date().toLocaleDateString('es-419');
    writeFile(wb, `IKE-Colaboradores-${currentDate}.xlsx`);
  };

  const createUserHandler = () => {
    if (loggedUser?.role === UserRoleEnum.COMPANY) {
      setSuccessTokenFunction(() => toggleUserModal);
      setTokenModalOpen(true);
    } else {
      toggleUserModal();
    }
  };

  const getFilteredData = () => {
    if (tableInstanceRef.current) {
      return tableInstanceRef.current.getPrePaginationRowModel().rows.map((row: any) => row.original);
    }
    return [];
  };

  const exportUserData = () => {
    if(loggedUser) {
      const currentTableData = getFilteredData();
      exportEmployeeData(companySelected, loggedUser, currentTableData);
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <input
        id="file-input-masive-update"
        type="file"
        hidden
        onChange={handleImportMasiveUpdate}
        accept=".xlsx,.xls"
      />
      <input
        id="file-input-masive-delete"
        type="file"
        hidden
        onChange={handleImportMasiveDelete}
        accept=".xlsx,.xls"
      />
      {loggedUser?.role === UserRoleEnum.ADMIN &&
        companies &&
        companies.length > 0 && (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '600px',
            marginBottom: '60px',
            margin: 'auto',
          }}>
            <TextField
              label="Buscar por nombre o email"
              variant="outlined"
              size="small"
              InputProps={{ style: { height: '56px' } }}
              InputLabelProps={{ style: { height: '36px', display: 'flex', alignItems: 'center' } }}
              style={{ marginRight: '10px', width: '400px' }}
              value={searchText}
              onChange={handleInputChange}
            />
            <CompaniesDropDown
              companies={companies}
              setCompanyFromDropDown={setCompanyFromDropDown}
            ></CompaniesDropDown>
          </div>
        )}

      {(loggedUser?.role === UserRoleEnum.COMPANY || (loggedUser?.role === UserRoleEnum.ADMIN && selectedCompanyId)) && !usersPreloaded && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
          <>
            {!loggedUser.virtualizedDashboard &&
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <Button
                  style={{ display: 'flex', alignItems: 'center' }}
                  startIcon={<Add />}
                  onClick={createUserHandler}
                  variant="outlined"
                  id="new-employee-button"
                  disabled={loading || (loggedUser?.role === UserRoleEnum.COMPANY && companySelected && companySelected.id == 0)}
                >
                  Crear Colaborador
                </Button>
              </Box>
            }
            {!loggedUser.virtualizedDashboard &&
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <ThemeProvider theme={violetTheme}>
                  <Button
                    style={{ display: 'flex', alignItems: 'center', borderColor: '#007bff', color: '#007bff' }}
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUpload />}
                    disabled={loading || (loggedUser?.role === UserRoleEnum.COMPANY && companySelected && companySelected.id == 0)}
                    onClick={handleClickImport}
                  >
                    Importar
                  </Button>
                  <input
                    id="file-input"
                    type="file"
                    hidden
                    onChange={handleImportEmployee}
                    accept=".xlsx,.xls"
                  />
                </ThemeProvider>
              </Box>
            }
            {loggedUser?.role === UserRoleEnum.COMPANY && !loggedUser.virtualizedDashboard && (
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#007bff', color: '#007bff' }}
                  variant="outlined"
                  startIcon={<CloudDownload />}
                  onClick={() => generateExcelTemplate(companySelected)}
                >
                  Descargar plantilla
                </Button>
              </Box>
            )}
            {loggedUser?.role === UserRoleEnum.COMPANY && tableData && tableData.length > 0 && (
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                  variant="outlined"
                  startIcon={<BrowserUpdatedIcon />}
                  onClick={exportUserData}
                >
                  Exportar
                </Button>
              </Box>
            )}
            {loggedUser?.role === UserRoleEnum.COMPANY && tableData && tableData.length > 0 && !loggedUser.virtualizedDashboard && (
              <>
                <Box mb={1} mr={2} display="flex" alignItems="stretch">
                  <Button
                    style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                    variant="outlined"
                    startIcon={<BorderColor />}
                    onClick={handleClickImportMasiveUpdate}
                  >
                    Modificar en Lote
                  </Button>
                </Box>
                <Box mb={1} mr={2} display="flex" alignItems="stretch">
                  <Button
                    style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={handleClickImportMasiveDelete}
                  >
                    Desactivar en Lote
                  </Button>
                </Box>
              </>
            )}
          </>
          {loggedUser?.role === UserRoleEnum.ADMIN && (
            <Box mb={2} mr={2} display="flex" alignItems="stretch" style={{marginBottom: '8px', textAlign: 'center'}}>
              <ThemeProvider theme={violetTheme}>
                <Button
                  style={{ display: 'flex', alignItems: 'center' }}
                  color="secondary"
                  component="label"
                  variant="outlined"
                  onClick={handleExportIKE}
                  startIcon={<CloudDownload />}
                  disabled={loading || !(tableData && tableData.length > 0)}
                >
                  Exportar IKE
                </Button>
              </ThemeProvider>
            </Box>
          )}
          {loggedUser?.role === UserRoleEnum.ADMIN && tableData && tableData.length > 0 && selectedCompanyId && (
            <Box mb={1} mr={2} display="flex" alignItems="stretch">
              <Button
                style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                variant="outlined"
                startIcon={<BrowserUpdatedIcon />}
                onClick={exportUserData}
              >
                Exportar
              </Button>
            </Box>
          )}
          {loggedUser?.role === UserRoleEnum.ADMIN && tableData && tableData.length > 0 && selectedCompanyId && (
            <>
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                  variant="outlined"
                  startIcon={<BorderColor />}
                  onClick={handleClickImportMasiveUpdate}
                >
                  Modificar en Lote
                </Button>
              </Box>
              <Box mb={1} mr={2} display="flex" alignItems="stretch">
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#fd7e14', color: '#fd7e14' }}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleClickImportMasiveDelete}
                >
                  Desactivar en Lote
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
      {loading ? <Skeleton
        style={{ width: '100%', height: '100px', marginTop: '50px' }} /> : tableData && tableData.length > 0 && (
        <MaterialReactTable
          initialState={{ density: 'compact' }}
          enableStickyHeader
          enableStickyFooter
          columns={columns}
          defaultColumn={{
            minSize: 20,
            maxSize: 160,
            size: 50,
          }}
          data={tableData}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: '0',
            },
          }}
          enableRowActions={!usersPreloaded && !loggedUser?.virtualizedDashboard}
          enableRowSelection={false}
          enableRowOrdering={false}
          enableHiding={false}
          enableFullScreenToggle={false}
          enableMultiRowSelection={false}
          positionActionsColumn="first"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyCellProps={() => ({
            id: `action-button-employee`,
          })}
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                handleEditButton(row.original);
                closeMenu();
              }}
              sx={{ m: 0 }}
              disabled={
                row.original.status === UserStatusEnum.AuthorizationPending
              }
              id="edit-button"
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Modificar
            </MenuItem>,

            <MenuItem
              key={1}
              onClick={() => {
                handleActivate(row.original);
                closeMenu();
              }}
              sx={{ m: 0 }}
              disabled={
                row.original.status === UserStatusEnum.Active ||
                row.original.status === UserStatusEnum.AuthorizationPending
              }
            >
              <ListItemIcon>
                <AdminPanelSettings />
              </ListItemIcon>
              Habilitar
            </MenuItem>,

            <MenuItem
              key={2}
              onClick={() => {
                handleDeactivateButton(row.original);
                closeMenu();
              }}
              sx={{ m: 0 }}
              disabled={
                row.original.status === UserStatusEnum.Deactivated ||
                row.original.status === UserStatusEnum.AuthorizationPending
              }
            >
              <ListItemIcon>
                <PersonOff />
              </ListItemIcon>
              Deshabilitar
            </MenuItem>,
            ...(loggedUser?.role === UserRoleEnum.ADMIN ? [
              <MenuItem
                key={3}
                onClick={() => {
                  handleUpdatePasswordButton(row.original);
                  closeMenu();
                }}
                sx={{ m: 0 }}
                disabled={
                  row.original.status === UserStatusEnum.Deactivated ||
                  row.original.status === UserStatusEnum.AuthorizationPending
                }
              >
                <ListItemIcon>
                  <PasswordIcon />
                </ListItemIcon>
                Cambiar contraseña
              </MenuItem>,
            ] : []),
          ]}
          onPaginationChange={setPagination}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 20, 50],
          }}
          state={{
            isLoading: loading,
            pagination,
            columnVisibility: { id: false }
          }}
        />
      )}
      {(!loading && !companies && !tableData) ||
        (!companies && tableData && tableData.length === 0 && (
          <StyledTitleContainer>
            <StyledP>Su listado se encuentra vacío</StyledP>
          </StyledTitleContainer>
        ))}
      {!loading &&
        loggedUser?.role === UserRoleEnum.ADMIN &&
        tableData &&
        tableData.length === 0 && (
          <StyledTitleContainer>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <Typography variant="h6">
                {!loading && selectedCompanyId
                  ? 'Esta empresa no posee colaboradores'
                  : 'Realiza una búsqueda para ver colaboradores'}
              </Typography>
            </Box>
          </StyledTitleContainer>
        )}
      <CreateEmployeeModal
        columns={columns}
        open={CreateCompanyModalOpen}
        onClose={() => setCreateCompanyModalOpen(false)}
        onSubmit={handleInviteUser}
        profileList={profileList}
      />
      {userToEdit && (
        <EditEmployeeModal
          open={EditCompanyModalOpen}
          userToEdit={userToEdit}
          onClose={() => setEditCompanyModalOpen(false)}
          onSubmit={handleEditUser}
          profileList={profileList}
        />
      )}
      {userToDeactivate && (
        <DeactivateEmployeeModal
          open={deactivateUserModalOpen}
          onClose={() => setDeactivateUserModalOpen(false)}
          onSubmit={handleDeactivateUser}
        />
      )}
      {userToUpdatePassword && (
        <EditEmployeePasswordModal
          open={updatePassUserModalOpen}
          onClose={() => setUpdatePassUserModalOpen(false)}
          onSubmit={handleUpdatePassword}
        />
      )}
      <ImportUserResultsModal
        open={importUserResultsModalOpen} resultsInfo={resultsInfo}
        onClose={() => setImportUserResultsModalOpen(false)}></ImportUserResultsModal>
      <ModalToken
        open={tokenModalOpen}
        onClose={() => {
          setTokenModalOpen(false);
          setSuccessTokenFunction(null);
        }}
        successToken={successTokenFunction}
      />
    </div>
  );
};

export default ListCompanyTable;
