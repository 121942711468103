import { Box, Button, Card, CardContent, Container, Grid, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import {
  getClickByService,
  getClicksChart,
  getCompanyHeadCount,
  getCompanyHeadCountByAge,
  getConsultingScore,
  getCoveredEmployees,
  getDeactivatedByMonth,
  getEmployeesByArea,
  getEmployeesByGeneration,
  getEnrolledEmployees,
  getLoginsByMonth,
  getLoginsVirtualizedData,
  getRegisterByMonth,
  getRotationByArea,
  getRotationBySalary,
  getRotationBySeniority,
  getRotationBySex,
  getTrendingService, getUserStatusHistory,
} from 'api/company/company.api';
import appImage from 'assets/app.png';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { firstLetterUppercase } from '../../utilities/i18n/helpers';
import { MonthsSpanish } from '../../enums/months.enum';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CompanyHeadCountByAgeDTO } from '../../types/company-head-count-by-age.dto';
import { ClickByServiceResponseDTO } from '../../types/click-by-service-response.dto';
import { MonthCounterResponseDTO } from '../../types/month-counter-response.dto';
import { EmployeeByAreaResponseDTO } from '../../types/employee-by-area-response.dto';
import { SeniorityRangeResponseDTO } from '../../types/seniority-range-response.dto';
import { getSalaryOnDemandData } from '../../api/transaction/transaction.api';
import { SalaryOnDemandReportDTO } from '../../types/salary_ondemand_report.dto';
import { RegisterByMonthDTO } from '../../types/register-by-month.dto';
import { DeactivatedByMonthDTO } from '../../types/deactivated-by-month.dto';
import {
  backgroundColors,
  backgroundColorsChartSex,
  backgroundColorsPaleteTwoChart,
  backgroundColorsScales,
  backgroundColorsSolid,
  borderColorsChartSex,
  borderColorsPaleteTwoChart,
  borderColorsScales,
  borderColorsSolid,
  colorMappingSalary,
  colorMappingSeniority,
  extendedBackgroundPeleteChart,
  extendedBorderPeleteChart,
  generationPieChart,
  optionsByAreaChart,
  optionsByMonthChart,
  optionsChartReasonsLastMonth,
  optionsConsultingChart,
  optionsDeactivatedEmployeesByMonth,
  optionsDonutChart,
  optionsEmployeeByAge,
  optionsEmployeeChart,
  optionsPieChart,
  optionsPieChartSpecialities,
  optionsRegisterEmployeesByMonth,
  optionsRotationByArea,
  optionsRotationBySalary,
  optionsRotationBySeniority,
  optionsRotationBySex,
  optionsServiceChart,
  optionsUserStatusHistoryChart,
  optionTotalUsersPieChart,
  salaryGroups,
  seniorityGroups,
  specialitiesChart,
  totalAmountMoneyChart,
  transactionAmountChart,
  trendingServiceEmployeeChart,
} from './chartjs-config';
import { getHealthDataService } from '../../api/health/health.api';
import Skeleton from 'react-loading-skeleton';
import { StarScoreDashboard } from './Components/StarScoreDashboard';
import { ReasonsLastTwelveMonthsDashboard } from './Components/ReasonsLastTwelveMonthsDashboard';
import { getRandomColorSolid, getRandomColorTransparent } from './utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../global-state/selectors';
import {
  DatasetDTO,
  DatasetNumberDTO,
  GenerationStatsReport,
  PieDatasetDTO,
  StackedBarDTO, StatusHistoryReport, TrendingServiceReport,
} from '../../types/reports.dto';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import {
  generateClicksChartReport,
  generateHeadCountDataReport,
  generateTrendingServiceReport,
} from '../../components/User/Employee/Utils/employee.utils';
import EwaListTable from './Components/EwaListTable';
import { storage } from '../../helpers/storage.helpers';
import { StorageItem } from '../../enums/storage-item.enum';


// Styled components
const RootBox = styled(Box)`
    flex-grow: 1;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const StyledCard = styled(Card)`
    margin: 2;
    text-align: center;
`;

const LinearProgressStyled = styled(LinearProgress)`
    margin: 3;
`;

export const StyledChartCardBox = styled(Card)`
    margin: 2px;
    text-align: center;
    padding: 40px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const StyledUpperChartCardBox = styled(Card)`
    text-align: center;
    box-shadow: none !important;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const ChartTitle = styled(Typography)`
    margin: 0 auto 0 auto;
    margin-bottom: 30px !important;
    color: rgba(76, 86, 115, 255);
    font-weight: 500 !important;
`;

const StyledChartContainer = styled(Container)`
    max-width: 1200px !important;
    display: flex;
    align-items: center;
    margin-top: 10;
    margin-bottom: 3;
    width: 100%;
`;

interface DataPoint {
  month: string;
  [key: string]: any;
}


export const Dashboard: FC = () => {
  const [loginByMonth, setLoginByMonth] = useState<MonthCounterResponseDTO[]>([]);
  const [coveredEmployees, setCoveredEmployees] = useState(0);
  const [enrolledEmployees, setEnrolledEmployees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [employeesByArea, setEmployeesByArea] = useState<EmployeeByAreaResponseDTO[]>([]);
  const [employeesByGeneration, setEmployeesByGeneration] = useState<GenerationStatsReport[]>([]);
  const [registerByMonths, setRegisterByMonths] = useState<RegisterByMonthDTO[]>([]);
  const [deactivatedByMonths, setDeactivatedByMonths] = useState<DeactivatedByMonthDTO[]>([]);
  const [rotationByArea, setRotationByArea] = useState<any[]>([]);
  const [rotationBySex, setRotationBySex] = useState<any[]>([]);
  const [rotationBySalary, setRotationBySalary] = useState<any[]>([]);
  const [rotationBySeniority, setRotationBySeniority] = useState<any[]>([]);
  const [salaryOnDemandDataLoaded, setSalaryOnDemandDataLoaded] = useState<SalaryOnDemandReportDTO[]>([]);
  const [healthDataLoaded, setHealthDataLoaded] = useState<any>(null);
  const loggedUser = useSelector(selectUser);
  const selectedCompany: {
    entityName: string;
    complaintsEnable: boolean;
    notificationsEnable: boolean;
    id: string;
    contractData: {activeContract: any, relatedProfiles: any}
  } | null = storage.session.get(StorageItem.COMPANY_SELECTED);
  const [transactionAmountDataChart, setTransactionAmountDataChart] = useState<{
    labels: string[];
    datasets: DatasetDTO[];
  } | null>(null);
  const [specialityCountDataChart, setSpecialityCountDataChart] = useState<any>(null);
  const [moneyAmountDataChart, seMoneyAmountDataChart] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [clicksChart, setClicksChart] = useState<MonthCounterResponseDTO[]>([]);
  const [clicksChartData, setClicksChartData] = useState<{
    labels: string[];
    datasets: DatasetDTO[];
  } | null>(null);
  const [clicksByService, setClicksByService] = useState<ClickByServiceResponseDTO[]>([]);
  const [scoreInformation, setScoreInformation] = useState<{ averageScore: number } | null>(null);
  const [clicksByServiceData, setClicksByServiceData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  } | null>(null);
  const [companyHeadCount, setCompanyHeadCount] = useState<MonthCounterResponseDTO[]>([]);
  const [userStatusHistory, setUserStatusHistory] = useState<StatusHistoryReport[]>([]);
  const [trendingService, setTrendingService] = useState<TrendingServiceReport[]>([]);
  const [companyHeadCountData, setCompanyHeadCountData] = useState<StackedBarDTO | null>(null);
  const [userStatusHistoryData, setUserStatusHistoryData] = useState<StackedBarDTO | null>(null);
  const [trendingServiceData, setTrendingServiceData] = useState<StackedBarDTO | null>(null);
  const [lastMonthLogins, setLastMonthLogins] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [companyHeadCountByAge, setCompanyHeadCountByAge] = useState<SeniorityRangeResponseDTO[]>([]);
  const [companyHeadCountByAgeData, setCompanyHeadCountByAgeData] = useState<{
    labels: string[];
    datasets: DatasetDTO[];
  } | null>(null);
  const [registerByMonthData, setRegisterByMonthData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [deactivatedByMonthsData, setDeactivatedByMonthsData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [rotationByAreaData, setRotationByAreaData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [rotationBySexData, setRotationBySexData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [rotationBySalaryData, setRotationBySalaryData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [rotationBySeniorityData, setRotationBySeniorityData] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [dataMonthly, setDataMonthly] = useState<{
    labels: string[];
    datasets: DatasetDTO[];
  } | null>(null);
  const [generationDataPie, setGenerationDataPie] = useState<PieDatasetDTO | null>(null);
  const [dataPie, setDataPie] = useState<PieDatasetDTO | null>(null);
  const [activeUsersPie, setActiveUsersPie] = useState<PieDatasetDTO | null>(null);
  const [dataPieSpecialities, setDataPieSpecialities] = useState<any>(null);
  const [loginByYear, setLoginByYear] = useState<number>(0);
  const [loginByYearTotals, setLoginByYearTotals] = useState<number>(0);
  const [dataByArea, setDataByArea] = useState<{
    labels: string[];
    datasets: DatasetNumberDTO[];
  } | null>(null);
  const [dataCountConsultingReason, setDataCountConsultingReason] = useState<any>(null);
  const [dataSpecialitiesLastMonth, setDataSpecialitiesLastMonth] = useState<any>(null);
  const [hasEwaProfile, setHasEwaProfile] = useState<boolean>(false);

  const loginsByMonthData = async () => {
    const data: any = await getLoginsByMonth();
    const currentMonthIndex = new Date().getMonth();
    const previousMonthIndex =
      currentMonthIndex === 0 ? currentMonthIndex : currentMonthIndex - 1;
    setLastMonthLogins(data[previousMonthIndex]?.counterUnique ? parseInt(data[previousMonthIndex]?.counterUnique) : 0);
    setLoginByMonth(data);
    const totalLoginsUnique = data.reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue.counterUnique;
    }, 0);
    setLoginByYear(totalLoginsUnique);
    setLoginByYearTotals(data[previousMonthIndex].counterTotal);
  };

  const loginsVirtualizedData = async () => {
    const data = await getLoginsVirtualizedData();
    setLastMonthLogins(data.monthCount);
    setLoginByYear(data.yearCount);
  };

  const getLabelsFromData = (data: DataPoint[]): string[] => {
    return data.map((dataPoint) =>
      firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])
    );
  };

  useEffect(() => {
    if (!loggedUser?.virtualizedDashboard) {
      loginsByMonthData();
    } else {
      loginsVirtualizedData();
    }
  }, []);

  useEffect(() => {
    if (loginByMonth.length > 0) {
      const labels = getLabelsFromData(loginByMonth);
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de inicios de sesión',
            data: loginByMonth.map((dataPoint: any) => dataPoint.counterTotal),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDataMonthly(chartData);
    }
  }, [loginByMonth]);

  useEffect(() => {
    if (salaryOnDemandDataLoaded.length > 0) {
      const labels = getLabelsFromData(salaryOnDemandDataLoaded);
      const chartDataTransactions = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de transacciones',
            data: salaryOnDemandDataLoaded.map((dataPoint) => dataPoint.count),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      const chartDataMoney = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de dinero',
            data: salaryOnDemandDataLoaded.map((dataPoint) => dataPoint.subtotal),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setTransactionAmountDataChart(chartDataTransactions);
      seMoneyAmountDataChart(chartDataMoney);
    }
  }, [salaryOnDemandDataLoaded]);

  useEffect(() => {
    if (registerByMonths.length > 0) {
      const labels = getLabelsFromData(registerByMonths);
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de registros nuevos',
            data: registerByMonths.map((dataPoint: RegisterByMonthDTO) => dataPoint.count),
            fill: false,
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setRegisterByMonthData(chartData);
    }
  }, [registerByMonths]);

  useEffect(() => {
    if (deactivatedByMonths.length > 0) {
      const labels = getLabelsFromData(deactivatedByMonths);
      const chartData = {
        labels,
        datasets: [
          {
            label: 'Bajas voluntarias',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterVoluntary),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Bajas involuntarias',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterInvoluntary),
            backgroundColor: 'rgba(224,224,224,255)',
            borderColor: '#b0b0b0',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Otras razones',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterNull),
            backgroundColor: 'rgba(204,229,255,255)',
            borderColor: '#7daed4',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDeactivatedByMonthsData(chartData);
    }
  }, [deactivatedByMonths]);

  useEffect(() => {
    if (rotationByArea.length > 0) {
      const labels = getLabelsFromData(rotationByArea);
      const allAreas = Array.from(
        new Set(
          rotationByArea.flatMap((dataPoint) => Object.keys(dataPoint.areas || {})),
        ),
      );
      const datasets = allAreas.map((area, index) => ({
        label: area,
        data: rotationByArea.map((dataPoint) => {
          const areaData = dataPoint.areas[area];
          return areaData
            ? areaData.counterVoluntary + areaData.counterInvoluntary + areaData.counterOther
            : 0;
        }),
        voluntary: rotationByArea.map((dataPoint) => {
          const areaData = dataPoint.areas[area];
          return areaData ? areaData.counterVoluntary : 0;
        }),
        involuntary: rotationByArea.map((dataPoint) => {
          const areaData = dataPoint.areas[area];
          return areaData ? areaData.counterInvoluntary : 0;
        }),
        other: rotationByArea.map((dataPoint) => {
          const areaData = dataPoint.areas[area];
          return areaData ? areaData.counterOther : 0;
        }),
        backgroundColor: backgroundColorsPaleteTwoChart[index % backgroundColorsPaleteTwoChart.length],
        borderColor: borderColorsPaleteTwoChart[index % borderColorsPaleteTwoChart.length],
        stack: 'combined',
        borderWidth: 2,
        maxBarThickness: 50,
        borderRadius: 10,
      }));
      const chartData = {
        labels,
        datasets,
      };
      setRotationByAreaData(chartData);
    }
  }, [rotationByArea]);

  useEffect(() => {
    if (rotationByArea.length > 0) {
      const labels = getLabelsFromData(rotationBySex);
      const allSex = Array.from(
        new Set(
          rotationBySex.flatMap((dataPoint) => Object.keys(dataPoint.sex || {})),
        ),
      );
      const datasets = allSex.map((sex, index) => ({
        label: sex,
        data: rotationBySex.map((dataPoint) => {
          const sexData = dataPoint.sex[sex];
          return sexData
            ? sexData.counterVoluntary + sexData.counterInvoluntary + sexData.counterOther
            : 0;
        }),
        voluntary: rotationBySex.map((dataPoint) => {
          const sexData = dataPoint.sex[sex];
          return sexData ? sexData.counterVoluntary : 0;
        }),
        involuntary: rotationBySex.map((dataPoint) => {
          const sexData = dataPoint.sex[sex];
          return sexData ? sexData.counterInvoluntary : 0;
        }),
        other: rotationBySex.map((dataPoint) => {
          const sexData = dataPoint.sex[sex];
          return sexData ? sexData.counterOther : 0;
        }),
        backgroundColor: backgroundColorsChartSex[index % backgroundColorsChartSex.length],
        borderColor: borderColorsChartSex[index % borderColorsChartSex.length],
        stack: 'combined',
        borderWidth: 2,
        maxBarThickness: 50,
        borderRadius: 10,
      }));
      const chartData = {
        labels,
        datasets,
      };
      setRotationBySexData(chartData);
    }
  }, [rotationBySex]);

  useEffect(() => {
    if (rotationBySalary.length > 0) {
      const labels = getLabelsFromData(rotationBySalary);
      const allRanges = Array.from(
        new Set(
          rotationBySalary.flatMap((dataPoint) => Object.keys(dataPoint.salary || {})),
        ),
      ).sort((a, b) => {
        return salaryGroups.indexOf(a) - salaryGroups.indexOf(b);
      });
      const datasets = allRanges.map((range) => {
        const { backgroundColor, borderColor } = colorMappingSalary[range] || {};

        return {
          label: range,
          data: rotationBySalary.map((dataPoint) => {
            const salaryData = dataPoint.salary[range];
            return salaryData
              ? salaryData.counterVoluntary + salaryData.counterInvoluntary + salaryData.counterOther
              : 0;
          }),
          voluntary: rotationBySalary.map((dataPoint) => {
            const salaryData = dataPoint.salary[range];
            return salaryData ? salaryData.counterVoluntary : 0;
          }),
          involuntary: rotationBySalary.map((dataPoint) => {
            const salaryData = dataPoint.salary[range];
            return salaryData ? salaryData.counterInvoluntary : 0;
          }),
          other: rotationBySalary.map((dataPoint) => {
            const salaryData = dataPoint.salary[range];
            return salaryData ? salaryData.counterOther : 0;
          }),
          backgroundColor,
          borderColor,
          stack: 'combined',
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
        };
      });

      const chartData = {
        labels,
        datasets,
      };

      setRotationBySalaryData(chartData);
    }
  }, [rotationBySalary]);

  useEffect(() => {
    if (rotationBySeniority.length > 0) {
      const labels = getLabelsFromData(rotationBySeniority);
      const allSeniorities = Array.from(
        new Set(
          rotationBySeniority.flatMap((dataPoint) => Object.keys(dataPoint.seniority || {})),
        ),
      ).sort((a, b) => seniorityGroups.indexOf(a) - seniorityGroups.indexOf(b));
      const datasets = allSeniorities.map((seniority) => {
        const { backgroundColor, borderColor } = colorMappingSeniority[seniority] || {};
        return {
          label: seniority,
          data: rotationBySeniority.map((dataPoint) => {
            const seniorityData = dataPoint.seniority[seniority];
            return seniorityData
              ? seniorityData.counterVoluntary + seniorityData.counterInvoluntary + seniorityData.counterOther
              : 0;
          }),
          voluntary: rotationBySeniority.map((dataPoint) => {
            const seniorityData = dataPoint.seniority[seniority];
            return seniorityData ? seniorityData.counterVoluntary : 0;
          }),
          involuntary: rotationBySeniority.map((dataPoint) => {
            const seniorityData = dataPoint.seniority[seniority];
            return seniorityData ? seniorityData.counterInvoluntary : 0;
          }),
          other: rotationBySeniority.map((dataPoint) => {
            const seniorityData = dataPoint.seniority[seniority];
            return seniorityData ? seniorityData.counterOther : 0;
          }),
          backgroundColor,
          borderColor,
          stack: 'combined',
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
        };
      });

      const chartData = {
        labels,
        datasets,
      };

      setRotationBySeniorityData(chartData);
    }
  }, [rotationBySeniority]);

  useEffect(() => {
    if (clicksByService.length > 0) {
      const labels = clicksByService.map((data: ClickByServiceResponseDTO) => data.service);
      const dataValuesTotal = clicksByService.map((data: ClickByServiceResponseDTO) => data.counter);
      const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
      const dataDonutChart = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de veces que se utilizó',
            data: dataValuesTotal,
            backgroundColor: backgroundColors.slice(0, labels.length),
            borderColor: borderColors.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      };
      setClicksByServiceData(dataDonutChart);
    }
  }, [clicksByService]);

  useEffect(() => {
    if (companyHeadCount.length > 0) {
      const labels = getLabelsFromData(companyHeadCount);
      const chartData = {
        labels,
        datasets: [
          {
            label: 'Colaboradores con actividad',
            data: companyHeadCount.map((dataPoint) => dataPoint.counterActive),
            backgroundColor: 'rgba(204,229,255,255)',
            borderColor: '#7daed4',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Colaboradores sin actividad',
            data: companyHeadCount.map((dataPoint) => dataPoint.counterPending),
            backgroundColor: 'rgba(224,224,224,255)',
            borderColor: '#b0b0b0',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setCompanyHeadCountData(chartData);
    }
  }, [companyHeadCount]);

  useEffect(() => {
    if (userStatusHistory.length > 0) {
      const labels = getLabelsFromData(userStatusHistory);
      const chartData = {
        labels,
        datasets: [
          {
            label: 'Colaboradores activos',
            data: userStatusHistory.map((dataPoint) => dataPoint.active),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Colaboradores pendientes',
            data: userStatusHistory.map((dataPoint) => dataPoint.pending),
            backgroundColor: 'rgba(255, 241, 181, 255)',
            borderColor: '#d1b757',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setUserStatusHistoryData(chartData);
    }
  }, [userStatusHistory]);

  useEffect(() => {
    if (trendingService.length > 0) {
      const labels = getLabelsFromData(trendingService);
      const allServices = Array.from(
        new Set(
          trendingService.flatMap((dataPoint) => Object.keys(dataPoint.services || {})),
        ),
      );
      const datasets = allServices.map((service, index) => ({
        label: service,
        data: trendingService.map((dataPoint) => {
          const areaData = dataPoint.services[service];
          return areaData
        }),
        backgroundColor: extendedBackgroundPeleteChart[index % extendedBackgroundPeleteChart.length],
        borderColor: extendedBorderPeleteChart[index % extendedBorderPeleteChart.length],
        stack: 'combined',
        borderWidth: 2,
        maxBarThickness: 50,
        borderRadius: 10,
      }));
      const chartData = {
        labels,
        datasets,
      };
      setTrendingServiceData(chartData);
    }
  }, [trendingService]);

  useEffect(() => {
    if (companyHeadCountByAge.length > 0) {
      const chartData = {
        labels: companyHeadCountByAge.map((dataPoint: CompanyHeadCountByAgeDTO) => dataPoint.seniorityRange),
        datasets: [
          {
            label: 'Cantidad de colaboradores',
            data: companyHeadCountByAge.map((dataPoint: CompanyHeadCountByAgeDTO) => dataPoint.counter),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setCompanyHeadCountByAgeData(chartData);
    }
  }, [companyHeadCountByAge]);

  useEffect(() => {
    if (employeesByArea.length > 0) {
      const labels = employeesByArea.map((employee) => employee.area);
      const dataValuesTotal = employeesByArea.map((employee) => parseInt(employee.total));
      const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
      const dataPieChart = {
        labels: labels,
        datasets: [
          {
            label: 'Usuarios',
            data: dataValuesTotal,
            backgroundColor: backgroundColors.slice(0, labels.length),
            borderColor: borderColors.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      };
      const dataByArea = {
        labels,
        datasets: [
          {
            label: 'Con inicio de sesión',
            data: employeesByArea.map((employee) => parseInt(employee.withLogin)),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'Stack 0',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Sin inicio de sesión',
            data: employeesByArea.map((employee) => parseInt(employee.withOutLogin)),
            backgroundColor: 'rgba(255,224,230,255)',
            borderColor: '#ff859e',
            stack: 'Stack 1',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDataPie(dataPieChart);
      setDataByArea(dataByArea);
    }
  }, [employeesByArea]);

  useEffect(() => {
    if (employeesByGeneration.length > 0) {
      const labels = employeesByGeneration.map((employee) => employee.generation);
      const dataValuesTotal = employeesByGeneration.map((employee) => parseInt(employee.total));
      const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
      const dataPieChart = {
        labels: labels,
        datasets: [
          {
            label: 'Colaboradores',
            data: dataValuesTotal,
            backgroundColor: backgroundColors.slice(0, labels.length),
            borderColor: borderColors.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      };
      setGenerationDataPie(dataPieChart);
    }
  }, [employeesByGeneration]);

  useEffect(() => {
    if (coveredEmployees > 0 || enrolledEmployees > 0) {
      const labels = ['Colaboradores activos', 'Colaboradores inactivos'];
      const notActiveUsers = coveredEmployees - enrolledEmployees;
      const dataValues = [enrolledEmployees, notActiveUsers];
      const dataActiveAndTotal = {
        labels,
        datasets: [
          {
            label: 'Colaboradores',
            data: dataValues,
            backgroundColor: ['rgba(14, 110, 253, 0.2)', 'rgba(224,224,224,255)'],
            borderColor: ['#0e6efd', '#b0b0b0'],
            borderWidth: 2,
          },
        ],
      };
      setActiveUsersPie(dataActiveAndTotal);
    }
  }, [coveredEmployees, enrolledEmployees]);

  useEffect(() => {
    if (clicksChart.length > 0) {
      const labels = getLabelsFromData(clicksChart);
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Servicios utilizados',
            data: clicksChart.map((dataPoint) => dataPoint.counter),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setClicksChartData(chartData);
    }
  }, [clicksChart]);

  useEffect(() => {
    switch (tabValue) {
      case 0:
        getActivityData();
        break;
      case 1:
        getActivityMobileData();
        break;
      case 2:
        getDemographicData();
        break;
      case 3:
        getHealthData();
        break;
      case 4:
        getRotationData();
        break;
      case 5:
        getSalaryData();
        break;
    }
    if(selectedCompany && selectedCompany.contractData && selectedCompany.contractData.relatedProfiles && tabValue == 5) {
      const hasServiceEWA = selectedCompany.contractData.relatedProfiles.some((profile: any) =>
        profile.service.some((service: any) => service.name === "EWA")
      );
      setHasEwaProfile(hasServiceEWA);
    } else if (selectedCompany && selectedCompany.id == '0') {
      setHasEwaProfile(true);
    }
  }, [tabValue]);

  const getSalaryData = async () => {
    setLoading(true);
    await salaryOnDemandData();
    setLoading(false);
  };

  const getHealthData = async () => {
    setLoading(true);
    await healthData();
    setLoading(false);
  };

  useEffect(() => {
    if (healthDataLoaded?.cie10?.countByMonth?.length > 0) {
      loadCountByMonth();
    }
    if (healthDataLoaded?.cie10?.countConsultingReasonLastMonth?.length > 0) {
      loadCountConsultingReasonLastMonth();
    }
    if (healthDataLoaded?.specialty?.consultingReasonSpeciality?.length > 0) {
      loadConsultingReasonSpeciality();
    }
  }, [healthDataLoaded]);

  function loadCountByMonth() {
    const cie10CountByMonth = healthDataLoaded.cie10.countByMonth;
    const labels = getLabelsFromData(cie10CountByMonth);
    const dataCountConsultingReason = {
      labels: labels,
      datasets: [
        {
          label: 'Consultas realizadas',
          data: cie10CountByMonth.map((dataPoint: any) => dataPoint.counter),
          fill: false,
          backgroundColor: 'rgba(235,224,255,255)',
          borderColor: '#b590ff',
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
        },
      ],
    };
    setDataCountConsultingReason(dataCountConsultingReason);
  }

  function loadCountConsultingReasonLastMonth() {
    const consultingReasonSpecialityArray = healthDataLoaded.cie10.countConsultingReasonLastMonth;
    const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
    const labels = consultingReasonSpecialityArray.map((data: any) => {
      const labelCode = data.consulting_reason.split('-')[0];
      const labelName = data.consulting_reason.split('-')[1];
      return (labelCode ? labelCode : '') + ' - ' + (labelName ? firstLetterUppercase(labelName) : data.consulting_reason);
    });
    const dataValuesTotal = consultingReasonSpecialityArray.map((data: any) => data.counter);
    const dataSpecialityLastMonth = {
      labels: labels,
      datasets: [
        {
          label: 'Consultas realizadas',
          data: dataValuesTotal,
          backgroundColor: backgroundColors.slice(0, labels.length),
          borderColor: borderColors.slice(0, labels.length),
          borderWidth: 1,
        },
      ],
    };
    setDataSpecialitiesLastMonth(dataSpecialityLastMonth);
  }

  function loadConsultingReasonSpeciality() {
    const consultingData = healthDataLoaded.specialty.consultingReasonSpeciality;
    const indexLastMonth = healthDataLoaded?.specialty?.consultingReasonSpeciality?.length - 2;
    const specialitiesLastMonth = healthDataLoaded?.specialty?.consultingReasonSpeciality[indexLastMonth];
    const labels = specialitiesLastMonth.consultingCount.map((data: any) => firstLetterUppercase(data.reason));
    const dataValuesTotal = specialitiesLastMonth.consultingCount.map((data: any) => parseInt(data.count));
    const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
    const labelsMonth = getLabelsFromData(consultingData);
    const chartDataMoney = {
      labels: labelsMonth,
      datasets: consultingData[0].consultingCount.map((reasonData: any, index: number) => {
        const colorSolid = getRandomColorSolid(index);
        const colorTransparent = getRandomColorTransparent(index);
        const datasetData = consultingData.map((monthData: any) => {
          const countData = monthData.consultingCount.find((item: any) => item.reason === reasonData.reason);
          return countData ? countData.count : 0;
        });
        const average = datasetData.reduce((sum: number, value: number) => sum + value, 0) / datasetData.length;

        return {
          label: firstLetterUppercase(reasonData.reason),
          data: datasetData,
          fill: true,
          backgroundColor: colorTransparent,
          borderColor: colorSolid,
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
          average: average,
        };
      })
        .sort((a: any, b: any) => a.average - b.average),
    };
    chartDataMoney.datasets.forEach((dataset: any) => {
      delete dataset.average;
    });
    const dataPieChart = {
      labels: labels,
      datasets: [
        {
          label: 'Consultas',
          data: dataValuesTotal,
          backgroundColor: backgroundColors.slice(0, labels.length),
          borderColor: borderColors.slice(0, labels.length),
          borderWidth: 1,
        },
      ],
    };
    setSpecialityCountDataChart(chartDataMoney);
    if (
      dataPieChart.datasets.length === 1 &&
      dataPieChart.datasets[0].data &&
      dataPieChart.datasets[0].data.every((value: number) => value === 0)
    ) {
      setDataPieSpecialities(null);
    } else {
      setDataPieSpecialities(dataPieChart);
    }
  }

  const healthData = async () => {
    const data = await getHealthDataService();
    setHealthDataLoaded(data);
  };

  const coveredEmployeesData = async () => {
    const data = await getCoveredEmployees();
    setCoveredEmployees(data);
  };

  const enrolledEmployeesData = async () => {
    const data = await getEnrolledEmployees();
    setEnrolledEmployees(data);
  };

  const employeesByAreaData = async () => {
    const data = await getEmployeesByArea();
    setEmployeesByArea(data);
  };

  const employeesByGenerationData = async () => {
    const data = await getEmployeesByGeneration();
    setEmployeesByGeneration(data);
  };

  const salaryOnDemandData = async () => {
    const data = await getSalaryOnDemandData();
    setSalaryOnDemandDataLoaded(data);
  };

  const getClicksChartData = async () => {
    const data = await getClicksChart();
    setClicksChart(data);
  };

  const getRegisterChartData = async () => {
    const data = await getRegisterByMonth();
    setRegisterByMonths(data);
  };

  const getDeactivatedChartData = async () => {
    const data = await getDeactivatedByMonth();
    setDeactivatedByMonths(data);
  };

  const getRotationByAreaChartData = async () => {
    const data = await getRotationByArea();
    setRotationByArea(data);
  };

  const getRotationBySexChartData = async () => {
    const data = await getRotationBySex();
    setRotationBySex(data);
  };

  const getRotationBySalaryChartData = async () => {
    const data = await getRotationBySalary();
    setRotationBySalary(data);
  };

  const getRotationBySeniorityChartData = async () => {
    const data = await getRotationBySeniority();
    setRotationBySeniority(data);
  };

  const clicksByServiceDataLoad = async () => {
    const data = await getClickByService();
    setClicksByService(data);
  };

  const getScores = async () => {
    const data = await getConsultingScore();
    setScoreInformation(data);
  };

  const getCompanyHeadCountData = async () => {
    const data = await getCompanyHeadCount();
    setCompanyHeadCount(data);
  };

  const getUserStatusHistoryData = async () => {
    const data = await getUserStatusHistory();
    setUserStatusHistory(data);
  };

  const getTrendingServiceData = async () => {
    const data = await getTrendingService();
    setTrendingService(data);
  };

  const getCompanyHeadCountByAgeData = async () => {
    const data = await getCompanyHeadCountByAge();
    setCompanyHeadCountByAge(data);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getActivityData = async () => {
    setLoading(true);
    if (!loggedUser?.virtualizedDashboard) {
      await loginsByMonthData();
    } else {
      await loginsVirtualizedData();
    }
    await coveredEmployeesData();
    await enrolledEmployeesData();
    await employeesByAreaData();
    setLoading(false);
  };

  const getActivityMobileData = async () => {
    setLoading(true);
    await getClicksChartData();
    await clicksByServiceDataLoad();
    await getScores();
    await getTrendingServiceData();
    setLoading(false);
  };

  const getDemographicData = async () => {
    setLoading(true);
    await getCompanyHeadCountData();
    await getUserStatusHistoryData();
    await getRegisterChartData();
    await employeesByAreaData();
    await getCompanyHeadCountByAgeData();
    await employeesByGenerationData();
    setLoading(false);
  };

  const exportHeadCountData = async () => {
    await generateHeadCountDataReport(companyHeadCount);
  };

  const exportTrendingServiceData = async () => {
    await generateTrendingServiceReport(trendingService);
  };

  const exportClicksChartData = async () => {
    await generateClicksChartReport(clicksChart);
  };

  const getRotationData = async () => {
    setLoading(true);
    await getDeactivatedChartData();
    await getRotationByAreaChartData();
    await getRotationBySexChartData();
    await getRotationBySalaryChartData();
    await getRotationBySeniorityChartData();
    setLoading(false);
  };

  let lastMonthPeriod = firstLetterUppercase(MonthsSpanish[format(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    'LLL',
    {
      locale: es,
    },
  ) as keyof typeof MonthsSpanish]);

  if (lastMonthPeriod.toLocaleLowerCase() == MonthsSpanish.dic) {
    lastMonthPeriod = firstLetterUppercase(MonthsSpanish.ene);
  }

  return (
    <RootBox>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: '#bdc3c7',
          marginBottom: '30px',
          background: '#ecf0f1',
          padding: '20px',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Dashboard tabs"
          indicatorColor="primary"
        >
          <Tab
            label="Actividad"
            {...a11yProps(0)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Servicios"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
              display: loggedUser?.virtualizedDashboard ? 'none' : 'block',
            }}
          />
          <Tab
            label="Demográfico"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Salud"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
              display: loggedUser?.virtualizedDashboard ? 'none' : 'block',
            }}
          />
          <Tab
            label="Rotación"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Salario bajo demanda"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
        </Tabs>
      </Box>
      {loading && <LinearProgressStyled color="info" />}
      <TabPanel value={tabValue} index={0}>
        <StyledChartContainer>
          <ChartTitle variant="h5" noWrap>
            Actividad
          </ChartTitle>
          <Container>
            <StyledUpperChartCardBox>
              <StyledChartCardBox style={{ marginRight: '10px' }}>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    marginBottom: '0px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: 'rgba(77,87,115,255)',
                      fontSize: '55px',
                      fontWeight: '900',
                    }}
                  >
                    {!loading ? loginByYear : <Skeleton style={{
                      width: '50px',
                      height: '68px',
                    }} />}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                  >
                    Inicios de sesión únicos durante el periodo {new Date().getFullYear()}
                  </Typography>
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox style={{ marginRight: '10px' }}>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    marginBottom: '0px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: 'rgba(77,87,115,255)',
                      fontSize: '55px',
                      fontWeight: '900',
                    }}
                  >
                    {!loading ? lastMonthLogins : <Skeleton style={{
                      width: '50px',
                      height: '68px',
                    }} />}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                  >
                    Inicios de sesión únicos durante el periodo {lastMonthPeriod}
                  </Typography>
                </div>
              </StyledChartCardBox>
              {!loggedUser?.virtualizedDashboard &&
                <StyledChartCardBox style={{ marginRight: '10px' }}>
                  <div
                    style={{
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '20px',
                      marginBottom: '0px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: 'rgba(77,87,115,255)',
                        fontSize: '55px',
                        fontWeight: '900',
                      }}
                    >
                      {!loading ? loginByYearTotals || 0 : <Skeleton style={{
                        width: '50px',
                        height: '68px',
                      }} />}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                    >
                      Inicios de sesión totales durante el periodo {lastMonthPeriod}
                    </Typography>
                  </div>
                </StyledChartCardBox>
              }
            </StyledUpperChartCardBox>
            <StyledChartCardBox style={{
              paddingBottom: coveredEmployees > 0 || enrolledEmployees > 0 ? '0px' : '40px',
            }}>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                marginTop={0}
                marginBottom={0}
              >
                <Grid item xs={10} md={4}>
                  <StyledCard sx={{ border: '10px solid #188754' }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ color: '#188754' }}
                      >
                        Colaboradores totales
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color: '#188754',
                          fontSize: '50px',
                          fontWeight: '900',
                        }}
                      >
                        {!loading ? coveredEmployees : <Skeleton style={{
                          width: '50px',
                          height: '68px',
                        }} />}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={10} md={4}>
                  <StyledCard sx={{ border: '10px solid #0e6efd' }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ color: '#0e6efd' }}
                      >
                        Colaboradores activos
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color: '#0e6efd',
                          fontSize: '50px',
                          fontWeight: '900',
                        }}
                      >
                        {!loading ? enrolledEmployees : <Skeleton style={{
                          width: '50px',
                          height: '68px',
                        }} />}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
              {activeUsersPie &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ width: '58%' }}>
                    <Pie data={activeUsersPie} options={optionTotalUsersPieChart} />
                  </div>
                </div>
              }
            </StyledChartCardBox>
            {!loggedUser?.virtualizedDashboard &&
              <>
                <ChartTitle variant="h5" noWrap>
                  Actividad mensual {new Date().getFullYear()}
                </ChartTitle>
                <StyledChartCardBox
                  style={{
                    minHeight: '600px',
                  }}
                >
                  {dataMonthly && <Bar data={dataMonthly} options={optionsByMonthChart} />}
                </StyledChartCardBox>
              </>
            }
          </Container>
          {dataByArea &&
            <Container>
              <ChartTitle variant="h5" noWrap>
                Colaboradores por área
              </ChartTitle>
              <StyledChartCardBox style={{
                minHeight: '600px',
              }}>
                {dataByArea && <Bar options={optionsByAreaChart} data={dataByArea} />}
              </StyledChartCardBox>
            </Container>
          }
        </StyledChartContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Servicios
              </ChartTitle>
              <StyledChartCardBox
                style={{
                  minHeight: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}
                  marginBottom={3}
                >
                  <Grid item xs={12} md={4}>
                    <img
                      src={appImage}
                      alt="Logo Tewispi"
                      style={{
                        width: '100%',
                        maxWidth: '250px',
                        marginBottom: '30px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div>
                      {clicksChartData && <Bar data={clicksChartData} options={optionsServiceChart} />}
                    </div>
                  </Grid>
                </Grid>
                <Box mb={1} mr={2}>
                  <Button
                    style={{ marginTop: '25px' }}
                    variant="outlined"
                    onClick={exportClicksChartData}
                    startIcon={<BrowserUpdatedIcon />}>
                    Exportar datos
                  </Button>
                </Box>
              </StyledChartCardBox>
              {scoreInformation && <StyledChartCardBox style={{ textAlign: 'start' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography style={{ fontWeight: 'bold', color: '#666666', marginBottom: '20px', fontSize: '1rem' }}>Calificación
                    promedio en las consultas</Typography>
                  <StarScoreDashboard score={scoreInformation.averageScore || 0}></StarScoreDashboard>
                </div>
                <p style={{
                  marginTop: '30px',
                  fontSize: '1rem',
                  color: '#666666',
                  fontWeight: '500',
                }}>*Media de puntuaciones en consultas durante los últimos 12 meses.</p>
              </StyledChartCardBox>}
              {clicksByServiceData && <StyledChartCardBox>
                <div style={{ height: '700px', display: 'flex', justifyContent: 'center' }}>
                  <Doughnut data={clicksByServiceData} options={optionsDonutChart} />
                </div>
              </StyledChartCardBox>}
              <StyledChartCardBox>
                <div>
                  {trendingServiceData && !loggedUser?.virtualizedDashboard &&
                    <Bar data={trendingServiceData} options={trendingServiceEmployeeChart} />}
                </div>
                <Box mb={1} mr={2}>
                  <Button
                    style={{ marginTop: '25px' }}
                    variant="outlined"
                    onClick={exportTrendingServiceData}
                    startIcon={<BrowserUpdatedIcon />}>
                    Exportar datos
                  </Button>
                </Box>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Demográfico
              </ChartTitle>
              <StyledChartCardBox>
                <div>
                  {companyHeadCountData && <Bar data={companyHeadCountData} options={optionsEmployeeChart} />}
                  <Box mb={1} mr={2}>
                    <Button
                      style={{ marginTop: '25px' }}
                      variant="outlined"
                      onClick={exportHeadCountData}
                      startIcon={<BrowserUpdatedIcon />}>
                      Exportar datos
                    </Button>
                  </Box>
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {userStatusHistoryData && <Bar data={userStatusHistoryData} options={optionsUserStatusHistoryChart} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {registerByMonthData && <Bar data={registerByMonthData} options={optionsRegisterEmployeesByMonth} />}
                </div>
              </StyledChartCardBox>
              {dataPie &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '55%' }}>
                      <Pie data={dataPie} options={optionsPieChart} />
                    </div>
                  </div>
                </StyledChartCardBox>
              }
              <StyledChartCardBox>
                <div>
                  {companyHeadCountByAgeData && <Bar data={companyHeadCountByAgeData} options={optionsEmployeeByAge} />}
                </div>
              </StyledChartCardBox>
              {generationDataPie &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '55%' }}>
                      <Pie data={generationDataPie} options={generationPieChart} />
                    </div>
                  </div>
                </StyledChartCardBox>
              }
            </Container>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Salud
              </ChartTitle>
              {dataCountConsultingReason &&
                <StyledChartCardBox style={{
                  minHeight: '600px',
                }}>
                  <Bar options={optionsConsultingChart} data={dataCountConsultingReason} />
                </StyledChartCardBox>
              }
              {dataPieSpecialities &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ height: '750px', display: 'flex', justifyContent: 'center' }}>
                    <Pie data={dataPieSpecialities} options={optionsPieChartSpecialities} />
                  </div>
                </StyledChartCardBox>
              }
              {specialityCountDataChart &&
                <StyledChartCardBox>
                  <div>
                    <Line data={specialityCountDataChart} options={specialitiesChart} />
                  </div>
                </StyledChartCardBox>
              }
              {dataSpecialitiesLastMonth &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ height: '900px', display: 'flex', justifyContent: 'center' }}>
                    <Doughnut data={dataSpecialitiesLastMonth} options={optionsChartReasonsLastMonth} />
                  </div>
                </StyledChartCardBox>
              }
              <StyledChartCardBox>
                <div>
                  <ReasonsLastTwelveMonthsDashboard />
                </div>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Rotación
              </ChartTitle>
              <StyledChartCardBox>
                <div>
                  {deactivatedByMonthsData &&
                    <Bar data={deactivatedByMonthsData} options={optionsDeactivatedEmployeesByMonth} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {rotationByAreaData &&
                    <Bar data={rotationByAreaData} options={optionsRotationByArea} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {rotationBySexData &&
                    <Bar data={rotationBySexData} options={optionsRotationBySex} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {rotationBySalaryData &&
                    <Bar data={rotationBySalaryData} options={optionsRotationBySalary} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {rotationBySeniorityData &&
                    <Bar data={rotationBySeniorityData} options={optionsRotationBySeniority} />}
                </div>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Salario bajo demanda
              </ChartTitle>
              <StyledChartCardBox>
                <div>
                  {moneyAmountDataChart && <Line data={moneyAmountDataChart} options={totalAmountMoneyChart} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {transactionAmountDataChart &&
                    <Bar data={transactionAmountDataChart} options={transactionAmountChart} />}
                </div>
              </StyledChartCardBox>
              { hasEwaProfile &&
                <StyledChartCardBox style={{minHeight: '400px', justifyContent: 'center'}}>
                  <EwaListTable exportButton={true} showTotalsBox={true}></EwaListTable>
                </StyledChartCardBox>
              }
            </Container>
          </>
        )}
      </TabPanel>
    </RootBox>
  );
};

export default Dashboard;
